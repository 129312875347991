body {
    margin: 0;
    padding: 0;
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modalInnerContainer {
    background: rgb(245, 243, 238);
    padding: 7px 5px;
    border: 3px solid rgb(217, 210, 197);
    border-radius: 2px;
    color: rgb(51, 51, 51);
    margin: 0 auto;
    position: relative;
    z-index: 101;
}

.modalOuterContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0;
    background: transparent;
}

.modalHeader {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: #1A191E;
    border-bottom: 1px solid #e5e5e5;
    padding-top: 4px;
    width: 100%;

}

.modalDismissButton {
    color: rgb(101, 176, 186);
    float: right;
    padding-top: 4px;
    margin-right: 43px;
}

.modalBodyContainer {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 30px 30px;
    position: relative;
}

.modalFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-right: 43px;
    padding-bottom: 14px;

}

.modalBody {
    width: 100%;
    margin-bottom: 10px;
    padding: 4px 4px 4px 0;
    border-radius: 3px;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: none;
}

.blueButton {
    -moz-box-shadow: inset 0px 39px 0px -24px #65b0ba;
    -webkit-box-shadow: inset 0px 39px 0px -24px #65b0ba;
    box-shadow: inset 0px 39px 0px -24px #65b0ba;
    background-color: #639ea4;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 12px;
    padding: 6px 12px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #31747c;
}

