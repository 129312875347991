
body {
    margin: 0;
    overflow: hidden;
}

[contenteditable=true]:empty::before {
    content: attr(placeholder);
    color: gray;
    text-align: left;
}

.navbar {
    overflow: hidden;
    background-color: #eee;
    /* background-image: linear-gradient(#CCCCCC, #EEEEEE, #BBBBBB); */
    box-shadow: 1px 1px #888888;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.navbar span {
    float: left;
    display: block;
    color: #333;
    /*background-image: linear-gradient(#CCCCCC, #666666, #BBBBBB);*/
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.navbar span:hover {
    background: #639ea4; /* #7c9acc; /* #accafc; */ /*#ccc; */
    background-image: linear-gradient(#DDDDDD, #639ea4, #CCCCCC); /*#6c9ac9*/
    color: white; /* black */;
}

.navbar span.active {
    background: #7c9acc; /* #accafc; */ /*#ccc; */
    background-image: linear-gradient(#CCCCCC, #666666, #BBBBBB);
    color: white; /* black */;
}

.subMenu1 {
    position: fixed;
    height: 15px;
    width: 180px;
    top: 47px;
    border: solid thin gray;
    cursor: pointer;
}

.subMenu2 {
    position: fixed;
    height: 15px;
    width: 180px;
    top: 91px;
    border: solid thin gray;
    border-top: solid thin lightgray;
    cursor: pointer;
}

.subMenu3 {
    position: fixed;
    height: 15px;
    width: 180px;
    top: 47px;
    border: solid thin gray;
    cursor: pointer;
}

.subMenu4 {
    position: fixed;
    height: 15px;
    width: 180px;
    top: 91px;
    border: solid thin gray;
    border-top: solid thin lightgray;
    cursor: pointer;
}

a.menuLink {
    color: black;
    padding: 14px 16px;
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif;
    font-size: 17px;
}

a.menuLink.disabled {
    color: #AAAAAA;
    pointer-events: none;
}

.logo {
    position: relative;
    float: left;
    top: 6px;
    left: 0px;
}

.loginBtn {
    float: right;
    margin: 15px 30px 15px;
    cursor: pointer;
}

.loginBtn:hover {
    background-color: #7c9acc;
    color: white;
}

.btnNormal {
    margin: 15px 30px 15px;
    cursor: pointer;
}

.btnNormal:hover {
    background-color: #7c9acc;
    color: white;
}

.requestBtnNormal {
    margin: 15px 15px 15px;
    cursor: pointer;
}

.requestBtnNormal:hover {
    background-color: #7c9acc;
    color: white;
}

.requestBtnDisable {
    cursor: not-allowed;
    pointer-events: none;
    margin: 15px 30px 15px;
    /*Button disabled - CSS color class*/
    color: #c0c0c0;
    background-color: #ffffff;

}

#retrieveButton {
    margin-left: 10px;
    cursor: pointer;
}

#retrieveButton:hover {
    background-color: #7c9acc;
    color: white;
}

.navSpacer {
    width: 0px;
    float: left;
}

.main {
    padding: 16px;
    margin-top: 55px;
    margin-bottom: 35px;
    height: auto;
    visibility: hidden;
    /*overflow: auto;*/
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-left: 10px;
    background-color: #dddddd;
    background-image: linear-gradient(#CCCCCC, #EEEEEE, #BBBBBB);
    color: #333;
    margin-bottom: -5px;
}

.footerLeft {
    position: relative;
    padding-left: 10px;
    float: left;
}

.footerRight {
    position: relative;
    padding-right: 40px;
    float: right;
}

#reviewHeading {
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: #EEEEEE;
    padding: 5px;
    padding-bottom: 20px;
    border: solid thin gray;
}

#testHeading {
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: #d1e0e3;
    padding: 5px;
    padding-left: 15px;
    /*padding-bottom: 20px;*/
    border: solid thin grey;
    border-radius: 5px;
}

.assignHeading {
    margin-top: 15px;
    margin-bottom: 10px;
    /*background-color: #d1e0e3;*/
    padding: 5px;
    padding-left: 5px;
    border: solid thin grey;
    border-radius: 5px;
    height: auto;
    background: -moz-linear-gradient(top, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f5f6', endColorstr='#c8d7dc', GradientType=0); /* IE6-9 */
}

.createHeading, .reviewHeading, .trackHeading {
    margin-top: 15px;
    margin-bottom: 10px;
    /*background-color: #d1e0e3;*/
    padding: 5px;
    padding-left: 5px;
    border: solid thin grey;
    border-radius: 5px;
    height: auto;
    background: -moz-linear-gradient(top, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f5f6', endColorstr='#c8d7dc', GradientType=0); /* IE6-9 */
}

.createHeadingLeft, .createHeadingRight {
    width: 45%;
    display: inline-block;
    margin: 0px;
    padding-top: 8px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;

}

.createHeadingLeft {
    width: 50%
}

.reviewHeadingLeft, .reviewHeadingRight {
    width: 50%;
    display: inline-block;
    margin: 0px;
    padding-top: 8px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}

.trackHeadingLeft, .trackHeadingRight {
    width: 47%;
    display: inline-block;
    margin: 0px;
    padding-top: 8px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;

}

.trackColumnHeadings {
    cursor: pointer;
    font-weight: normal;
}

#colBatch {
    width: 11%;
    height: 37px;
}

#colName {
    width: 12%;
}

#colEmail {
    width: 13%;
}

#colSurveyNum {
    width: 13%;
}

#colAssigned {
    width: 12%;
}

#colStarted {
    width: 13%;
}

#colCompleted {
    width: 11%;
}

.trackTableCells {
    border: solid thin gray;
    width: 12.5%;
}

.trackTableEmail {
    border: none;
    width: 14%;
}

.reviewHeadingInner {
    width: 100%;
    display: inline-block;
    margin: 0px;
    padding-top: 8px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}

.reviewColumnHeading {
    text-align: center;
    font-weight: normal;
    cursor: pointer;
    border: solid thin transparent;
}

.reviewColumnHeading.reviewHeadingNoPointer {
    cursor: default;
}

#testNameInput {
    width: 129px;
    background-color: white;
}

#assignTestNameInput {
    width: 190px;
    background-color: #eeeeee;
    margin-top: 5px;
}

.assignHeadingTName, .superUserHeader {
    width: 99.6%;
    /*display: inline-block;*/
    /*text-align: center;*/
    margin-bottom: 10px;
    padding: 4px;
    padding-left: 0px;
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}

.spcPanel {
    display: inline-block;
    text-align: center;
}

.AssignTableHeader {
    background-color: #d1e0e3;
    padding: 5px;
    padding-left: 15px;
    margin-top: 0px;
    width: 100%;
    border-top: solid thin gray;
    border-left: solid thin gray;
    border-right: solid thin gray;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.AssignAnonBody {
    background-color: #d1e0e3;
    padding: 5px;
    padding-left: 15px;
    margin-top: 0px;
    width: auto;
    /* border-top: solid thin gray; */
    border-left: solid thin gray;
    border-right: solid thin gray;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
}

.AssignAnonFooter {
    background-color: #d1e0e3;
    padding: 5px;
    padding-left: 15px;
    margin-top: 0px;
    width: auto;
    border-bottom: solid thin gray;
    border-left: solid thin gray;
    border-right: solid thin gray;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.AssignAnonTableContainer {
    margin-right: 8px;
    width: auto;
    height: auto;
    border: solid thin gray;
    background-color: white;
}

.AssignTable {
    background-color: white;
    padding: 5px;
    padding-left: 15px;
    margin-top: 0px;
    width: 100%;
    border-left: solid thin transparent;
    border-right: solid thin transparent;
}

.assignSubmitFooter, .anonAssignSubmitFooter {
    text-align: center;
    background-color: #d1e0e3;
    border-left: solid thin gray;
    border-right: solid thin gray;
    border-bottom: solid thin gray;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.assignTableDiv {
    background-color: white;
    padding: 2px;
    width: auto;
    border: solid thin gray;
}

#testNameInput:focus {
    outline: none;
}

.numberParticipants {
    margin-top: 5px;
    height: 20px;
}

.boldText {
    font-weight: bold;
}

/*.inputSubText {*/
/*position: relative;*/
/*top: -10px;*/
/*left: 110px;*/
/*display: inline;*/
/*}*/

#reviewInstructions {
    font-weight: bold;
    color: #222222;
}

#sessionIDinput {
    width: 250px;
}

#testNamePicker {
    width: 250px;
}

.nameAndSession {
    margin-top: 5px;
    margin-bottom: 10px;
}

.namePart {
    margin-right: 15px;
}

.sessionLabel {
    margin-left: 2px;
}

.TINpart {
    margin-left: 2px
}

.assessResult {
    background-color: #d1e0e3;
    padding: 5px;
    margin-top: 10px;
}

.testResultsTable {
    width: 100%;
}

.transcriptCell {
    background-color: white;
    padding: 2px;
    border: solid thin gray;
    width: 46%;
}

.playCell {
    padding: 2px;
    width: 4%;
}

.fas {
    cursor: pointer;
}

.mainVisible {
    visibility: visible;
}

#testSelector {
    padding-left: 100px;
}

.testElementsTable {
    background-color: #d1e0e3;
    padding: 5px;
    padding-left: 15px;
    margin-top: 0px;
    width: 100%;
    border-left: solid thin gray;
    border-right: solid thin gray;
}

.promptText {
    background-color: white;
    padding: 2px;
    width: 47%;
    border: solid thin gray;
}

.assignText1 {
    background-color: white;
    padding: 2px;
    width: 16%;
    border: solid thin gray;
    text-align: center;
}

.assignText2 {
    background-color: white;
    padding: 2px;
    width: 36%;
    border: solid thin gray;
}

.assignText3 {
    background-color: white;
    padding: 2px;
    width: 33%;
    border: solid thin gray;
}

/* .assignText4 {
    background-color: white;
    padding: 2px;
    width: 18%;
    border: solid thin gray;
    padding-left: 10px;
} */

.assignText4 {
    background-color: transparent;
    padding: 2px;
    width: 15%;
    border: solid thin transparent;
    padding-left: 10px;
}

.seqNumbHdr {
    width: 5%;
    border: none;
    background-color: transparent;

}

.promptTextHdr {
    width: 45%;
    border: none;
    background-color: transparent;
}

.promptIdHdr {
    border: none;
    background-color: transparent;
    width: 40%;
    display: none; /* none vs inline-block*/
}

.getResponseHdr {
    width: 10%;
    border: none;
    background-color: transparent;
}

.spokenPromptHdr {
    width: 5%;
    border: none;
    background-color: transparent;
    text-align: left;
}

.imageURLHdr {
    width: 18%;
    max-width: 100px;
}

.promptId {
    display: none;
    background-color: white;
    padding: 2px;
    width: 30%;
    border: solid thin gray;
}

.getResponse {
    text-align: center;
    background-color: white;
    padding-right: 5px;
    width: 10%;
    border: solid thin gray;
}

.imageURL {
    width: 18%;
    max-width: 100px;
    border: solid thin gray;
    padding: 5px;
    cursor: text;
    height: auto;
    overflow-wrap: break-word;
}

.spokenPrompt {
    background-color: transparent;
    text-align: center;
    padding: 2px;
    width: 5%;
    border: solid thin gray;
}

#getResponsePicker {
    width: 100%;
}

.testName {
    background-color: #d1e0e3;
    padding: 5px;
    padding-left: 15px;
    margin-top: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: solid thin gray;
    border-left: solid thin gray;
    border-right: solid thin gray;

}

.testCheckAndSaver {
    background-color: #d1e0e3;
    padding-bottom: 20px;
    height: 30px;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: solid thin gray;
    border-right: solid thin gray;
    border-bottom: solid thin gray;
}

#createButtonGroup {
    display: inline-block;
}

#reorderPrompts {
    margin: 10px;
    cursor: pointer;
}

#playPromptsInOrder {
    margin: 10px;
    cursor: pointer;
}

#saveTest {
    margin: 10px;
    cursor: pointer;
}

.seqNumb {
    text-align: center;
    background-color: white;
    padding: 2px;
    width: 5%;
    border: solid thin gray;
}

.seqNumbDiv {
    cursor: text;
    background-color: white;
    border: solid thin white;
}

.promptDiv {
    cursor: text;
    /* background-color: white; */
    background-color: #f0f0f0;
    border: solid thin white;
}

.imageURLdiv {
    background-color: #f0f0f0;
}

.assignDiv {
    cursor: text;
    background-color: white;
    border: solid thin white;
    width: 100%;
}

.anonAssignDiv {
    cursor: text;
    background-color: white;
    border: solid thin white;
    width: 99%;
    color: black
}

.untouched {
    color: #ccc;
}

.spcCodeDiv, .inviteDiv {
    cursor: default;
    background-color: white;
    border: solid thin white;
    width: 100%;
}

.spcHeaderCol {
    width: 16%;
}

.participantNameHeaderCol {
    width: 33%;
}

.participantEmailHeaderCol {
    width: 33%;
}

.participantInviteHeaderCol {
    width: 18%;
}

.deleteIcon {
    position: relative;
    float: right;
    margin: 2px;
}

.deleteIcon.hidden {
    visibility: hidden;
}


/*  disable the chrome outline on focus */
/*[contenteditable]:focus {*/
/*outline: 0 solid transparent*/
/*}*/

/*[input]:focus {*/
/*outline: 0 solid transparent*/
/*}*/

/*[input]:blur {*/
/*outline: 0 solid transparent*/
/*}*/

/* ************************************* */

.sessionSelector {
    min-width: 254px;
    min-height: 22px;
    padding: 1px 0;
    border: 1px solid black;
    display: inline-block;
    background: #FFFFFF;
}

.btn {
    cursor: pointer;
}

.btn:hover {
    background-color: #7c9acc;
    color: white;
}

.inputError {
    margin: 7px;
    color: red;
}

.row_invisible {
    display: none;
}

.row_inactive {
    color: grey;
}

/*technique taken from:
https://stackoverflow.com/questions/19502835/is-it-possible-to-change-between-two-fontawesome-icons-on-hover
*/

.hover-control:hover .far,
.hover-control .fas {
    display: none;
}

.hover-control:hover .fas {
    display: inline;
}

.unhover-control .fas {
    display: none;
}

.unhover-control .far {
    display: inline;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

/***************************modal styles***************************/
.modal-header {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: #1A191E;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.modal-header:before, .modal-header:after {
    content: " ";
    display: table;
}

.modal-header:after {
    clear: both;
}

.modal-body {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 30px 30px;
    position: relative;
}

.modal-footer {
    padding: 5px;
    padding-left: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.btnModal {
    margin: 15px 0px 15px;
    cursor: pointer;
}

.btnModal:hover {
    background-color: #7c9acc;
    color: white;
}

input[type=checkbox] {
    /* 20% larger-sized Checkboxes */
    -ms-transform: scale(1.2); /* IE */
    -moz-transform: scale(1.2); /* FF */
    -webkit-transform: scale(1.2); /* Safari and Chrome */
    -o-transform: scale(1.2); /* Opera */
    transform: scale(1.2);
    padding: 10px;
    cursor: pointer;
}

input[type=checkbox]:after {
    position: relative;
    display: block;
    width: 11px;
    height: 11px;
    border: 1px solid #808080;
    content: "";
    background: transparent;
}

/*.saveIndicatorLocator {*/
/*position: relative;*/
/*width: 0px;*/
/*height: 0px;*/
/*padding-right: 130px;*/
/*float: right;*/
/*top: -238px;*/
/*cursor: pointer;*/
/*}*/

.saveCreateIndicatorLocator {
    position: relative;
    width: 0px;
    height: 0px;
    padding-right: 130px;
    float: right;
    top: -188px;
}

.saveAnonCreateIndicatorLocator {
    position: relative;
    width: 0px;
    height: 0px;
    padding-right: 130px;
    float: right;
    top: -140px;
}

.saveAssignIndicatorLocator {
    position: relative;
    width: 0px;
    height: 0px;
    padding-right: 130px;
    float: right;
    top: -254px;
    cursor: pointer;
}

.saveIndicatorGreen {
    height: 12px;
    width: 120px;
    position: absolute;
    color: green;
    cursor: pointer;
    /* border-radius: 12px;
    border: solid thin gray; */
}

.saveIndicatorOrange {
    height: 12px;
    width: 120px;
    font-weight: bold;
    position: absolute;
    color: rgb(253, 134, 98);
    cursor: pointer;
    /*border-radius: 12px;
    border: solid thin gray;*/
}

.lineIndicatorOrange {
    height: 8px;
    width: 8px;
    font-weight: bold;
    position: absolute;
    background-color: rgb(253, 134, 98);
    border-radius: 8px;
    border: solid thin gray;
    margin-top: 5px;
    margin-left: 2px;
}

.lineIndicatorGreen {
    height: 8px;
    width: 8px;
    position: absolute;
    background-color: lightgreen;
    border-radius: 8px;
    border: solid thin gray;
    margin-top: 5px;
    margin-left: 2px;
}

.lineIndicatorGray {
    height: 8px;
    width: 8px;
    position: absolute;
    background-color: darkgray;
    border-radius: 8px;
    border: solid thin gray;
    margin-top: 5px;
    margin-left: 2px;
}

.lineIndicatorTransparent {
    height: 8px;
    width: 8px;
    position: absolute;
    background-color: transparent;
    border-radius: 8px;
    border: solid thin transparent;
    margin-top: 5px;
    margin-left: 2px;
}

.lineIndicatorRed {
    height: 8px;
    width: 8px;
    font-weight: bold;
    position: absolute;
    background-color: red;
    border-radius: 8px;
    border: solid thin red;
    margin-top: 5px;
    margin-left: 2px;
}

.systemError {
    height: 20px;
    color: red;
    display: block;
    text-align: center;
    background-color: #d1e0e3;
}

.badEmail {
    color: red;
}

.assignHeadingRequest {
    width: 47%;
    display: inline-block;
    margin: 0px;
    padding-top: 8px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}

.assignHeadingCsv, .bucketHeading {
    display: inline-block;
    width: 47%;
    margin: 0px;
    padding-top: 8px;
    padding-left: 20px;
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
    text-align: center;
    float: right;
}

.csvInput {
    display: inline-block;
    margin: 6px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
    /*cursor: pointer*/
}

.csvInfo {
    position: absolute;
    top: 158px;
    right: 32px;
    z-index: 10;
    cursor: pointer;
    font-size: 20px;
}

.editInviteInfo {
    position: absolute;
    top: 288px;
    right: 26px;
    z-index: 10;
    cursor: pointer;
    font-size: 16px;
}

.csvModalText {
    font-size: 16px;
    margin-bottom: 10px;
}

.orDiv {
    display: inline-block;
    width: 6%;
}

.orDiv h2 {
    text-align: center;
    padding-top: 20px;
    color: #444444;
}

.batchSelector {
    display: none;
}

.batchSelector.active {
    display: flex;
    text-align: center;
    width: 100%;
}

.batchDisplay {
    display: none;
}

.batchDisplay.active {
    display: block;
    margin-top: 12px;
    text-align: center;

}

/*https://leaverou.github.io/bubbly/*/

.speech-bubble-response {
    position: relative;
    left: 8px;
    background: #E15A32;
    border-radius: .4em;
    padding: 6px;
    color: #fff;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.speech-bubble-response.active {
    color: black;
    font-size: 18px;
}

.speech-bubble-response:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-left-color: #E15A32;
    border-right: 0;
    border-top: 0;
    margin-top: -3.5px;
    margin-right: -7px;

}

.speech-bubble-prompt {
    position: relative;
    left: -8px;
    /* background: #65B0BA; */
    background: #51a6a7;
    border-radius: .4em;
    padding: 6px;
    color: #fff;
    font-size: 15px;
    margin: 10px;
    cursor: default;
}

.speech-bubble-prompt.active {
    color: black;
    font-size: 18px;
}

.speech-bubble-prompt:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-right-color: #00aabb;
    border-left: 0;
    border-top: 0;
    margin-top: -3.5px;
    margin-left: -7px;
}

.inset {
    /*width: 47%;*/
    display: inline-block;
    margin: 0px;
    padding: 8px 4px;
    /*text-align: center;*/
    border-radius: 3px;
    border: 1px solid transparent;
    border-top: none;
    border-bottom: 1px solid #DDD;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}

.trackSelectorTop {
    display: inline-block;
}

input[type=text].rc, select.rc {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input[type=password].rc, select.rc {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.review-filter-prompt {
    font-size: 14px;
    margin-top: 7px;
    /*font-weight: bold;*/
    width: 20%;
    cursor: default;
}

/*autocomplete css*/

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    /*width: 100%;*/
    z-index: 2000;
    position: absolute;
    background: white;

}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #ccc;
    color: black;
    cursor: pointer;
    /*font-weight: 700;*/
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

/*
end autocomplete
 */

.dateClass {
    /*font-family: sans-serif;*/
    /*font-size: 12px;*/
    font-weight: 700;
    border: 1px lightblue;

}

/*
spinner classes
 */

.react-spinner {
    position: relative;
    width: 100px;
    height: 100px;
    top: 40%;
    left: 50%;
}

.react-spinner_bar {
    -webkit-animation: react-spinner_spin 1.2s linear infinite;
    -moz-animation: react-spinner_spin 1.2s linear infinite;
    animation: react-spinner_spin 1.2s linear infinite;
    border-radius: 5px;
    background-color: black;
    position: absolute;
    width: 20%;
    height: 7.8%;
    top: -3.9%;
    left: -10%;
}

@keyframes react-spinner_spin {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.15;
    }
}

@-moz-keyframes react-spinner_spin {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.15;
    }
}

@-webkit-keyframes react-spinner_spin {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.15;
    }
}

/*
review
 */

.reviewTableHeader {
    background-color: #d1e0e3;
    /*padding: 5px;
    padding-left: 10px; */
    margin-top: 2px;
    width: 100%;
    border-top: solid thin gray;
    border-left: solid thin gray;
    border-right: solid thin gray;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}

.reviewTableBody {
    /*background-color: #d1e0e3;*/
    position: relative;
    top: 1px;
    background-color: transparent;
    /* padding: 5px;
      padding-left: 15px;
     margin-top: -6px;*/
    margin-top: 0px;
    height: auto;
    width: 100%;
    /* border-left: solid thin gray; */

}

.reviewTd {
    background-color: white;
    padding: 2px;
    /*width: 16%;*/
    border: solid thin gray;
    /*text-align: center;*/
}

.reviewFooterLeft {
    text-align: center;
    background-color: #d1e0e3;
    border-left: solid thin gray;
    border-right: solid thin gray;
    border-bottom: solid thin gray;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;

}

.reviewFooterRight {
    text-align: center;
    background-color: #d1e0e3;
    border-left: solid thin gray;
    border-right: solid thin gray;
    border-bottom: solid thin gray;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

/*adapted from: https://stackoverflow.com/questions/7510753/trhover-not-working*/

table tr.reviewRow:hover td {
    background-color: #eee;
    cursor: pointer;
}

table tr.reviewRow.active td {
    /*background-color: #7c9acc;*/
    background-color: #51a6a7;
    color: white;
}

.review-selector-section {
    width: 32%;
}

.track-rightSelector-labels, .track-leftSelector-labels {
    margin-top: 4px;
}

.track-selector-section {
    width: 32%;
}

.review-selector {
    display: flex;
    justify-content: space-around;
    text-align: center;
    font-size: 12px;
}

.track-selector {
    display: inline;
    margin: 8px;
    justify-content: space-around;
    text-align: center;
    font-size: 12px;
}

.transcriptResponsePlay {
    position: relative;
    left: 22px;
    top: -6px;
    width: 5%;
    text-align: center;
    padding-top: 23px;
    color: gray;
}

.transcriptResponsePlay:hover {
    color: black;
}

.saveReviewIndicatorLocator {
    position: relative;
    width: 0px;
    height: 0px;
    padding-right: 130px;
    float: right;
    top: -162px;
    cursor: pointer;
}

.export-clipboard {
    padding: 50px;
    background: lightgrey;

}

.export-prompt {
    text-align: center;
    font-weight: 400;
    color: rgb(0, 0, 102);
    font-family: Arial, sans-serif;
}

.export-preview {
    font-size: 1.17em;
    color: rgb(0, 0, 102);
    font-family: Arial, sans-serif;
    margin: 40px 50px 0;
}

.edit-read {
    cursor: default
}

.edit-read.active {
    cursor: text
}

.btnFA {
    cursor: pointer;
}

.btnFA:hover {
    background-color: #7c9acc;
    color: white;
}

.btnFA:disabled {
    background-color: lightgrey;
    cursor: not-allowed;

}

.no-email {
    background-color: red;
}

.gradient-fill {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f2f5f6+0,e3eaed+37,c8d7dc+100;Grey+3D+%234 */
    background: #f2f5f6; /* Old browsers */
    background: -moz-linear-gradient(top, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f2f5f6 0%, #e3eaed 37%, #c8d7dc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f5f6', endColorstr='#c8d7dc', GradientType=0); /* IE6-9 */

}

.orangeButton {
    -moz-box-shadow: inset 0px 39px 0px -24px #e36348;
    -webkit-box-shadow: inset 0px 39px 0px -24px #e36348;
    box-shadow: inset 0px 39px 0px -24px #e36348;
    background-color: #e15a32;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 12px;
    padding: 6px 12px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #31747c;
}

.orangeButton:hover {
    background-color: #e15a32;
    color: black;
}

.orangeButton:active {
    position: relative;
    top: 1px;
}

.orangeButton:disabled, hover {
    background-color: #d8917b;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
    cursor: default;
}

.orangeButtonSmall {
    -moz-box-shadow: inset 0px 39px 0px -24px #e36348;
    -webkit-box-shadow: inset 0px 39px 0px -24px #e36348;
    box-shadow: inset 0px 39px 0px -24px #e36348;
    background-color: #e15a32;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 11px;
    padding: 6px 10px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #31747c;
}

.orangeButtonSmall:hover {
    background-color: #e15a32;
    color: black;
}

.orangeButtonSmall:active {
    position: relative;
    top: 1px;
}

.orangeButtonSmall:disabled, hover {
    background-color: #d8917b;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
}

.blueButton {
    -moz-box-shadow: inset 0px 39px 0px -24px #65b0ba;
    -webkit-box-shadow: inset 0px 39px 0px -24px #65b0ba;
    box-shadow: inset 0px 39px 0px -24px #65b0ba;
    background-color: #639ea4;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 12px;
    padding: 6px 12px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #31747c;
}

.blueButton:hover {
    background-color: #639ea4;
    color: black;
}

.blueButton:active {
    position: relative;
    top: 1px;
}

.blueButton:disabled, hover {
    background-color: #b5c2c4;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
}

.blueButtonSmall {
    -moz-box-shadow: inset 0px 39px 0px -24px #65b0ba;
    -webkit-box-shadow: inset 0px 39px 0px -24px #65b0ba;
    box-shadow: inset 0px 39px 0px -24px #65b0ba;
    background-color: #639ea4;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    color: white;
    font-family: Arial;
    font-size: 11px;
    padding: 4px 10px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #31747c;
}

.blueButtonSmall:hover {
    background-color: #639ea4;
    color: black;
}

.blueButtonSmall:active {
    position: relative;
    top: 1px;
}

.blueButtonSmall:disabled, hover {
    background-color: #b5c2c4;
    background-color: #b5c2c4;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
}

.uploadButtonSmall {
    -moz-box-shadow: inset 0px 39px 0px -24px #65b0ba;
    -webkit-box-shadow: inset 0px 39px 0px -24px #65b0ba;
    box-shadow: inset 0px 39px 0px -24px #65b0ba;
    background-color: #639ea4;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: normal;
    color: white;
    font-family: Arial;
    font-size: 11px;
    padding: 4px 10px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #31747c;
}

.uploadButtonSmall:hover {
    background-color: #639ea4;
    color: black;
}

.uploadButtonSmall:active {
    position: relative;
    top: 1px;
}

.uploadButtonSmall:disabled, hover {
    background-color: #b5c2c4;
    background-color: #b5c2c4;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
}

input[type="file"] {
    z-index: 100;
    opacity: 0;
}

.dragDropBox {
    display: flex;
    width: 400px;
    height: 50px;
    padding-left: 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #fff, 0 1px 0 #fff;
}

.uploadBackgroundNormal {
    width: 96px;
    height: 22px;
    position: relative;
    top: -45px;
    pointer-events: none;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAWCAIAAACwgQ6zAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwwAADsMBx2+oZAAAA7lJREFUWEftmM9PE0EUx/sv9T/gyLVHbiRkT8Zbj/VKPBkSr6Am1i5yYVNjSFaJMSQGN9hgIWIVaukPpG6r0hYK/sj42b522IwrrTdN9uWbdOfNd96++e57M4HE29rRYbPV6nR//PypYhsYUrQ6vcqxX6p9TPDDOPvq9fVHTy3HjQGQAkGQpdL0E9QOA4MRAyAL4iTQKa6dSCAL4iRoOWMihgbixAJdhVigMfgLgY4OA7aYddjDs7fjnqrv1k5bCAyPGDp1GapuXcin8EccoSn1XaY2upev3giy6Yn/KEgpiKODiGm/IBxKjMRYImFJ2OCHtyAmG7kCcCYS6NQvVZVKpreS6cxqOqNUm9DFgttVfavQEA7DKkNnn2ey9M7hr8GveBmtkWwp6bXw3yLO+YHWiCWrTZZkgGpu7X3DX1Ktfe0E0+mM5eSED3hjR/WT+QNNsJYWWPKsFcSsviMfHoJ8BHjKo11ovp6NxEQCrcA796e9T5LcjOPeyi+sbO7+SSBU6KiL1NqB5Tj4p5dcNrxxErxFnQQqSFqzjuutLZ6yZKc9eMVx9pDlQajkzVzqBlo4ereRMBIQXC2Q4RmLiQR6M9iwtVnVnlkb5MhPqT7Ltcnra2W3woNd0vxyMbd7rlaoiJAK4K7tqrOKVf4iYkk0qR0hsFuJLBaeAkYCUoxXCzSkDszo/UhAGy/QE5sP1TI+FCC/cIV7+YxUUGRmxb5adnZVv7FcuxRomQz6DetdkGXKdpPpeeIof4uU9G7DLSZlpZcbCczcZHaMQOEWm6LF7EU9G4mJBMrCO6vynbWH046PWSyUIltse9PtBY1zrPnqa/2Jr2ZpGb/0/PPl636vTUB/oXU9CFXXu40Eb/wnWoz675Rf6htBrptU4VOx4EQKRC2o5vbeBfygHVCzo9TMOkeS663naAq5O4Jz56L9mCPJXpSYKz6X4DDU9hmhCv+HQGDqttPbX4MplvTaHLRGflognqmCXnnI5+qeyh9IMaPdanqeS1CmVn0lfcHB7+UX9IFSUyp1Y1sOafFoC1/MYwUarhkYPWt4MH2H/glwJhIIoJG0LpBriN1Oped1GzOcZji6hjXfaHW0SKYXZErUEegzCOjrHKHFoxG+mI0EBCyRsNye4YU4DY84w2t/x1CguXv23N0Hc3eyMS6BIPfskUDGXIwRAoH4i/7a/YfGRAyALMG/O/yTbvaFZ8zFAMiCOIn9RhOdGMR1pIEUCIIsiJMolqv8+CcdxrRcbBhSUDvvG8c7H2q/ACjzjCcHLejGAAAAAElFTkSuQmCC");
}

.uploadBackgroundHover {
    width: 96px;
    height: 22px;
    position: relative;
    top: -45px;
    pointer-events: none;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAWCAIAAACwgQ6zAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAU8SURBVFhH7ZhfTFt1FMe/LRZoKVuBC2wwEJjQjiGjgWQQGsZQCImKfTALgUSJODHxQR4w+ICaEB9cDMn24MOsJPNhSJYlm0sW2UjG3DDLXBF1G4OwTJSNDSkURin/RvF7+7vWOwYr+uKD/QQu53d67u/+ft97zvk1aAZHx/Th4VH6CFOUIUyrRQhgxeebnpv3eOcXlx9pRh5M7IiP7fzpxtWxiYUVnxLy/yYyTLs3Kb46L+eua0rjejjbNXznu9Fx5cMQf7EvJbEyM0PLymLuKL4QKigLxdGy74Qqa10oC8UJdeUghAQKgmZ1dfXtb7qV0cb0D8HZXCHsg4fqYK65PYlf61vL222IK6OTw9n6Vuvp14BsDh0nBtDRSCMNKG//QMQQhvXUtwK9tKtqkHjgvPDzjDhj7wCO0S4ArKcPc57AJIKAXwzVUwm4MMfPeVVo5LRcMJor1PHqLQjERpTBenzxavkzivlUui9P6dqqrU11wI45br65/+ChjlljzRzcWJgUMbMLmOGQF786adcbY5q4N+P0fXTXf1LeDmoktmSt1WN7yzIVOXejChVCozMnBrKKj0UVtdB2Xrkb/Vnjc+93YmImqxjCSST5kuA3ZfhEE9zpDXZE5yguSeIt835z5qH/j389Anq2A9v8u1BcjA9GcIG8wMiFHyprS1NslF/yAOGzkjdsRPn4CaiCqeO49T27ZCsFDKOT6BqpG+/6NLG2rKeLKvQWlB1GXDYF8iyn9R8ZrHzhgpf5dX14d45FspXIU0gu0xz3EEnzWQPShHM9dEyr3HgkqwOuKH/Xw8h4WzpQpIw3QXCBuENT/8WU2t3iFcrPKGXSJmAK/HbgeNfBi4jM9F/H7yEG16TCF6kOhylx2L6nwnX5WDRvGJRVoDr0c2+7ClO/7dqDe0O6uLK0MuupIxfxdcX+YhQwd6DsuZvV363UBT+S0wqxYkjUC1AX7EYM88f+sbDX1P5GBBdIY+Bm4qCX36dAp+dFFssEBDLcPQscPUljedE/qSreGCMtreAR5rGyFB4RpniBLZwnbAsWxyiWtTA1RlfLfO1x3u2xVwd2qy4xk/zcv6cl6gVEBS+Xx0psK38NVCkIwQWK1WPCvA2TLiQrHna76DMVqOlUZ3j/PZb7KRqGGDyABZN/UFXB8M2BfQnQM7ESJM/EzS2KG/eZmwN9eJO5CUkPycYuLNeX27xj5OjJxAMDHP3zEnsa/6LEgh/zVNqUndf/ETcvr5jHjbO5w2Rmf3nsZQbISMZ4celtx+dgTfnVNJ1uTCqy6/i+ip4/e9aCIZ5Wcmu75LiQUfwjkvdxToe9w3uZRxIKLJKUW6IxM9HC5en+a4JnEN9SXtnOW8uNTrty4lprX5KK7aMLcot5EuaC9ZWSoauRrBQOE3l7g92Y+zJta64U3vS6o/l7QG4rWaUwV7ZAnxrFjtCQd7ztGtpkP1uU+cN3gCQ2GXUPIkEPZjXqjsOaRfp5tYdspm1t9nvQ8Kh35jensAt2SWy0rnm4+5yZ+SyeVDo5nO9zpthY1XIzCMQzATPz00QM4SE42MtMdNG2SDBalOrg7SN9jGdiyZqm2KiS5Bx0wSWnbQDxaGGvWYCAt1gwwGl5tozfuqR4/c9yx5eoPUS9gHXh9yBZoLe+6sSqD6urijsE0Wig0X75RrW/B/lWQuqshYJQFjbpFZ8vUsc+E2ItlIXiaN0eb9FOnnwh1kJZKI52xuOt3pu/35IVyqMAlIKCUBaKo/nlzu+GiIitRn2MMSr0T3sBK4u5M+2ZW1ha/hOn+O8iGJuowgAAAABJRU5ErkJggg==");
}

.uploadBackgroundDisabled {
    width: 96px;
    height: 22px;
    position: relative;
    top: -45px;
    pointer-events: none;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAWCAIAAACwgQ6zAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAOXSURBVFhH7ZhNTyJBEIb5S/wafwU3jhwMJ09cPHjZi0cOhIRkkN3DxpBZN5k1oq7LahTGBT8GQVS+xbC9z1DYTsYR8LabncqbMF1dXV39TlWXTuS4fvWr0Xro9SeT3yqUqUDFQ79/7jRP6tcRfhgfnVY+fvlqFM0QACogBFrOG80IucPAZxECQAt5FIGnMHcCAS2QE6HkfBMhNCAnJGgeQoIW4B0EdR3XWsRwBmhaFXOsnozKvRgw7DIsOjJUfUeMx9g/24iZUk8yVeu/bF1zoxmIvuuG5PrRTkS0XuB1JUJgLBG3BOyz9x5BRA4yB9gsRdD4zibSaHw3Gk9k4wml2ri2y2ZPjYzytdgwdBgWbZ6J0hphv4X9hZXQHMmRolYLfQo/o4rmiCXZBksSQDV2W0/obXVrayWIxRNGMSP2gB07ahTNVbSBkV5nyc9b16dTJR7XibZHU30+hbbXs4FYiqAydo+3MetGgtsomqncern04y2CYKGjxskCfzoU0MfSJgeu9dxdVM9lQcJKF02rsDlSQ7hztxjd7NSG4iqayiRX4aKgTxsIXwCC+QT5NAuxFEEN98CPRqmuNek8yBCfUiOWa5Htm3Xzgof8qbavHmUuRqpMRnhYAJ/yphqcG/V7IUu8Se6IAacVzyLeKeALQJJxPkEz06n4aj8QmC0maC/Pi2r5XhQgPm+GW7mEQzoU7cDI7KHaLx6r4fX+FVk20+8TwfDaqLZ5TubNaHwNP6q5S0j6tN4Sk7TSy30BbKSYXUCQt8RWKLH8pp4NxFIE7WA3qPOetYbbjpdpl+3AEjspmQO3cCjJmb3qOHtNlaZkmqfVu5ftXucmoL7guu1y7ejTBoId/4oSI/879jfdEaTdJEs3drkQSBC5oBoHrfEsFNjsKLWx7f43Y21nFCef9g733hnff+ZKym+Kz/IdTXDm6mSAq8N/gyCw8qEwONvCUiRqtbloffFpgngmC4b2zJ7WvZKrSDLDXTa+RhOUqWxTSV1w8Vu5dX2hNJVKrh7IJS0aLd7GvJCg2ZqpULM+DaJ76FvAZimCABxJ6QJpQ5x2Jb6my5hhjOFzG9b2vlKHi2h8XaaEHYG+g4Bu5xAtGg1vY/YFIGCJuKV7ehei9GlE6V37Gu8g6P9ESNACuASFnzvewuxzx303/GAWDGiBnMjZZQOeGIR5pAEVEAItkBM5rNb4uev2GFNyoSCTyYTcOb10vtv1P0NTYQz27j6iAAAAAElFTkSuQmCC");
}

.uploadDiv {
    position: relative;
    overflow-wrap: break-word;
    left: 103px;
    width: 210px;
    height: auto;
}

.anonAssignTicket {
    width: 20%;
    background-color: white;
    border: solid thin gray;
    color: black;
    text-align: center;
}

.anonAssignGroup {
    width: 35%;
    background-color: white;
    border: solid thin gray;
}

.survey-name-prompt {
    text-align: right;
    width: 50%;
    margin-top: 14px;
    margin-left: 6px;
}

.survey-dropdown, .template-dropdown, .groups-multiselect {
    width: 45%;
    margin-top: 4px;
    margin-right: 20px;
}

.batch-name-prompt {
    text-align: right;
    width: 30%;
    margin-top: 14px;
    margin-left: 6px;
}

.trackRowsContainer {
    overflow-Y: auto;
    background-color: white;
    margin-left: 10px;
    position: relative;
    float: left;
    width: 98%;
    border: solid thin gray;
}

.trackTableContainer {
    background-color: transparent;
    border: none;
    padding: 0;
    width: 100%;
    margin-top: 0;
}

.emailModal-body {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 15px 0px;
    position: relative;
}

.textInputWidgetMatch {
    height: 24px;
    border: thin solid lightgray;
    border-radius: 4px;
    padding: 3px 3px 3px 5px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #999;
}

.email-invite-subheader {
    position: relative;
    top: 10px;
    left: 7px;
    text-align: left;
    /*padding-bottom: 14px;*/
    border-bottom: solid thin #EEEEEE;
    display: flex;
    width: 90%;
    font-size: 14px;
    font-family: arial;
    color: rgb(45, 78, 117);
}

.editable-template {
    font-size: 16px;
    padding: 12px;
    background-color: #FFF9DD;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 39%), 0 -1px 1px #fff, 0 1px 0 #fff;
    min-height: 270px;
}

.editable-template-container {
    overflow-Y: auto;
    max-height: 300px;
    min-height: 300px;
}

.embedCreate-template-container {
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: white;
}

.embedModalEditContainer {
    position: relative;
    float: left;
    width: 44%;
    height: 100%;
    background-color: white;
}

.embedModalWrkPanel {
    height: 240px;
    min-width: 750px;
    border: solid thin #DDDDDD;
    padding: 10px;
    padding-bottom: 20px;
}

.embedable-template {
    font-family: arial;
    font-weight: bold;
    font-size: 15px;
    text-align: left;
    padding: 10px;
    background-color: #EEEEEE;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
    max-height: 140px;
    min-height: 140px;
    width: 200px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.invite-instructions {
    /* position: relative; */
    font-size: 16px;
    padding: 12px;
    background-color: lightcyan;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}

.embed-instructions {
    padding: 12px;
    padding-right: 20px;
}

.left-white-circle {
    border: solid thin transparent;
    position: relative;
    text-align: center;
    background-color: transparent;
    box-shadow: 0px 0px 24px #d8dbe3;
    border-radius: 100px;
    height: 86px;
    margin: 0 auto;
    width: 86px;
    max-width: 86px;
}

#msgText {
    font-family: arial;
    font-weight: normal;
    font-size: 15px;
    height: 130px;
    max-height: 140px;
    vertical-align: top;
    width: 190px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-top: 20px;
    padding-left: 10px;
}

.simCardContainer { /*float: right; */
    width: 55%;
    height: 100%
}

#simCard {
    border-radius: 8px;
    width: 312px;
    height: 195px;
    margin: auto;
    padding: 0px;
    background: -webkit-linear-gradient(to right, #86A3A8, #d1e0e3);
    background: -moz-linear-gradient(to right, #86A3A8, #d1e0e3);
    background: linear-gradient(to right, #86A3A8, #d1e0e3);
}

.embedCodePanel {
    position: relative;
    width: 100%;
    display: inline-block;
    border: solid thin transparent;
    background-color: lightcyan;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}

.swatch {
    cursor: pointer;
    margin-bottom: 3px;
    position: relative;
    padding: 3px;
    float: left;
    border-radius: 8px;
    color: white;
    height: 20px;
    width: 100px;
    border: solid thin white;
}

.colorCode {
    border-radius: 8px;
    height: 20px;
    width: 100px;
    margin-top: 2px;
    padding-left: 4px;
    background-color: lightyellow;
}

.swatch:hover {
    border: solid thin black
}

.big-menu-link {
    position: relative;
    cursor: pointer;
    color: white;
    font-family: arial;
    font-size: 20px;
    font-weight: 200;
    padding: 5px 10px;
    margin-top: 20px;
    margin-right: 30px;
}

.signup-form-panel, .login-form-panel, .reset-password-form-panel, .staff-list-panel {
    max-width: 600px;
    margin: 10px auto;
    border: 1px solid black;
    padding: 50px;
    box-shadow: 2px 2px 5px lightgray;
    border-radius: 8px;
    background-color: #eee;
}

.email-in-use, .bad-login {
    visibility: hidden;
    color: red;
    text-align: center;
    margin-top: -15px;
}

.email-in-use.active, .bad-login.active {
    visibility: visible;
    color: red;
    text-align: center;
    margin-top: -15px;
}

/**********************************************sidebar submenu***************************************************/

/* Fixed sidenav, full height */
.sidenav {
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #555;
    overflow-x: hidden;
    padding-top: 20px;
}

/* Style the sidenav links and the dropdown button */
.submenu-btn {
    font-family: Serif;
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 17px;
    color: #fff;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

/* On mouse-over */
.dropdown-btn:hover, .submenu-btn:hover, .submenu-btn.active {
    background: #639ea4;
    background-image: linear-gradient(#DDDDDD, #639ea4, #CCCCCC);
    color: white;
}

/* Main content */
.subMenuMainPanel {
    position: fixed;
    left: 200px; /* Same as the width of the sidenav */
    top: 48px;
    font-size: 17px; /* Increased text to enable scrolling */
    background: white;
    overflow: hidden;

}

/* Add an active class to the active dropdown button */
/*.active {*/
/*font-family: 'serif';*/
/*text-decoration: none;*/
/*font-size: 17px;*/
/*background-color: green;*/
/*background-color: black;*/
/*color: white;*/
/*}*/

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
}

.dropdown-container.active {
    display: block;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
    float: right;
    padding-right: 8px;
}

/**************************************************************************************************/

.confirm_title {
    visibility: visible;
    text-align: center;
}

.confirm_title.hidden {
    visibility: hidden;
}

.staff-td {
    height: 18px;
    padding: 2px;
    background-color: white;
    border: solid thin gray;
}

.staff_email_message {
    display: none;
    text-align: center;
    color: red;
}

.staff_email_message.active {
    display: block;
    text-align: center;
    color: red;
}

/*choose your plan*/

.blackButton {
    -moz-box-shadow: inset 0px 39px 0px -24px #555555;
    -webkit-box-shadow: inset 0px 39px 0px -24px #555555;
    box-shadow: inset 0px 39px 0px -24px #555555;
    background-color: #555555;
    opacity: 0.8;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 12px;
    padding: 6px 12px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #31747c;
}

.blackButton:hover {
    -moz-box-shadow: inset 0px 39px 0px -24px transparent;
    -webkit-box-shadow: inset 0px 39px 0px -24px transparent;
    box-shadow: inset 0px 39px 0px -24px transparent;
    background-color: white;
    opacity: 0.5;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #000000;
    display: inline-block;
    cursor: pointer;
    color: #000000;
    font-family: Arial;
    font-size: 12px;
    padding: 6px 12px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #31747c;
}

/**/

.createDevOnly {
    position: absolute;
    top: 113px;
    left: 32px;
    z-index: 10;
    cursor: pointer;
    font-size: 20px;
}

.surveyHeaderStyles {
    background-color: #d1e0e3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 0 auto;
    width: 100%;
    padding-bottom: 0px;
    padding-right: 20px;
    border-bottom: solid thin transparent;
    box-sizing: border-box;
}

.projectOuterContainerStyles {
    margin: 8px;
    border: solid thin #555555;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #d1e0e3;
}

.surveysBodyContainerStyle {
    overflow-y: auto;
    border: solid thin #555555;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;
}

.surveyRowTDStyle {
    text-align: center;
    border: solid thin black;
    background-color: invisible;
}

.staffHeaderStyles {
    background-color: #d1e0e3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 auto;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 4px;
    border-bottom: solid thin transparent;
    box-sizing: border-box;
}

.staffTableContainerStyles {
    overflow-y: auto;
    background-color: white;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
    position: relative;
    width: auto;
    border: solid thin gray;
}

.staffOuterContainerStyles {
    overflow-y: auto;
    background-color: #d1e0e3;
    margin-left: 0px;
    position: relative;
    width: auto;
    border: none;
}

.staffContainerStyles {
    margin: 8px;
    margin-top: 10px;
    width: auto;
    border: solid thin gray;
    border-radius: 5px;
    background-color: #d1e0e3;
}

.staffInnerContainer {
    float: right;
    color: green;
    padding-right: 15px;
    position: relative;
    top: -25px;
}

.staffTableStyle {
    background-color: transparent;
    width: 100%;
    margin-top: 0px;
}

.surveyTooltipStyle {
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    margin: -7px -20px -7px -20px;
    background-color: lightgoldenrodyellow;
    padding: 20px 6px 20px 6px;
}

.createTooltipStyle {
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    margin: -7px -20px -7px -20px;
    background-color: lightgoldenrodyellow;
    padding: 20px 6px 20px 6px;
}

.emailInviteKeyboardShortcuts {
    text-align: center;
    font-family: sans-serif;
    font-size: 15px;
    border: solid thin black;
    margin: -10px -20px -10px -20px;
    padding: 10px;
}

.emailInviteInstructions {
    font-size: 15px;
    width: 250px;
    /*font-family: serif;*/
}

.everyChangeSaved {
    background-color: lightgoldenrodyellow;
    margin: -10px -20px -10px -20px;
    padding: 8px 20px 8px 20px;
    font-size: 16px;
    font-weight: normal;
}

.editReviewTable {
    background-color: lightgoldenrodyellow;
    margin: -10px -20px -10px -20px;
    padding: 8px 20px 8px 20px;
    font-size: 16px;
    font-weight: normal;
}

.sortTrackTable {
    background-color: lightgoldenrodyellow;
    margin: -8px -20px -8px -20px;
    padding: 8px 20px 8px 20px;
    font-size: 16px;
    font-weight: normal;
}

.reviewTranscriptEdits {
    background-color: lightgoldenrodyellow;
    margin: -8px -20px -8px -20px;
    padding: 8px 20px 8px 20px;
    font-size: 16px;
    font-weight: normal;
}

.csvFormatting {
    background-color: lightgoldenrodyellow;
    margin: -8px -20px -8px -20px;
    padding: 8px 20px 8px 20px;
    font-size: 16px;
    font-weight: normal;
}

.chart-check {
    font-size: 12px;
}

.chart-check.active {
    color: red;
}

.chart-timeframe {
    padding-left: 24px;
    border-left: 1px dotted gray;
    border-top: 1px dotted gray;
    border-right: 1px dotted gray;
    border-bottom: 1px dotted gray;

    visibility: hidden;
}

.chart-timeframe.active {
    visibility: visible;
}

.chart-type {
    margin-top: 20px;
    margin-left: 20px;
    visibility: hidden;
}

.chart-type.active {
    visibility: visible;
}

.chart-group-select {
    border: solid 1px #c8d7dc;
    visibility: hidden;
}

.chart-group-select.active {
    visibility: visible;
}

.chart-group-select-byTimeframe {
    border-left: solid 1px #c8d7dc;
    border-right: solid 1px #c8d7dc;
    border-bottom: solid 1px #c8d7dc;
    visibility: hidden;
}

.chart-timeframe {
    border: 1px solid #c8d7dc;
}

.chart-group-select-byTimeframe.active {
    visibility: visible;
}

.chart-checkbox {
    margin-right: 7px;
}

.fromDate {
    width: 40%;
}

.chartSurveySelect {
    font-weight: 700;
    border: 1px lightblue;
}

.survey-and-date {
    width: 70%;
    height: 100%;
    background: white;
    float: left;
}

.survey-select {
    width: 100%;
    height: 50%;
    font-size: 12px;
}

.date-range-select-container {
    width: 100%;
    height: 50%;
}

.date-range-select-inner {
    width: 95%;
    height: 20%;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    align-content: flex-end;
}

.to-div {
    font-size: 14px;
    margin-top: 6px;
    width: 10%;
    cursor: default;
}

.from-date {
    width: 45%;
    float: left;
    font-size: 12px;
}

.to-date {
    width: 45%;
    float: right;
    font-size: 12px;
}

.chart-type-radio {
    width: 30%;
    height: 100%;
    float: right;
}

.right-box {
    width: 18%;
    float: right;
    padding: 4px;
    box-sizing: border-box;
    margin: 4px;
}

.trackAnonShell {
    /* border: solid thick #c8d7dc; */
    margin-bottom: -35px;
}

.trackAnonFooter, .analysisFooter {
    width: auto;
    height: 40px;
    background-color: #c8d7dc;
    margin-top: 35px;
    text-align: center;
    /*border-top: solid thin gray;*/
    border-bottom: solid thin gray;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.closeTrackAnonButton {
    margin-top: 10px;
}

.chart-group-select {
    border: solid 1px #c8d7dc;
}

/**************************************Chart containers**************************************/

.flex-container {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    justify-content: center;
}

.flex-container > div {
    border: dotted thin gray;
    width: 800px; /* width of graph container */
    margin: 35px;
    text-align: center;
    min-height: 400px;
    padding-top: 5px;
}

.sideDiv {
    position: relative;
    height: 0px;
    width: 60px;
    float: right;
    left: 50px;
    top: -375px;
    text-align: center;
}

.analysisSideDiv {
    position: relative;
    height: 0px;
    width: 60px;
    float: right;
    left: 50px;
    top: -645px;
    text-align: center;
}

.analysisSideDiv.collapsed {
    position: relative;
    height: 0px;
    width: 60px;
    float: right;
    left: 50px;
    top: -45px;
    text-align: center;
}

.btnDiv {
    width: 50px;
    background-color: transparent;
    border: none;
}

.addChartBtn {
    width: 50px;
    cursor: pointer;
    position: relative;
    float: right;
    left: 15px;
    top: 5px;
}

.chart-title-input {
    text-align: center;
    margin: 5px 0 3px 30px;
    width: 72%;
    font-size: 18px;
    font-weight: bold;
    float: left;
    display: inline;
    position: relative;
    top: -10px;
    border: 2px solid #c8d7dc;
}

.analyze-chart-title-input {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.chart-title-input:focus {
    outline: none;
    border: 2px solid #a8b7bc;
}

.chart-download-icon-container {
    position: relative;
    left: 730px;
    height: 25px;
    width: 25px;
    cursor: pointer;
}

.analysis-download-icon {
    position: relative;
    float: right;
    margin-top: 10px;
    top: -25px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.analyze-hide-container {
    position: relative;
    float: right;
    top: -25px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
    height: 25px;
    color: gray;
    font-size: 22px;
}

.analysis-close-container {
    position: relative;
    float: right;
    top: -25px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 12px;
    height: 25px;
    margin-right: 12px;
    color: gray;
    font-size: 22px;
}

.chart-download-icon {
    position: relative;
    top: -55px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.chart-close-container {
    cursor: pointer;
    margin-top: -45px;
    height: 25px;
    margin-right: 12px;
    float: right;
    color: gray;
    font-size: 22px;
}

.chart-hide-container {
    cursor: pointer;
    margin-top: -45px;
    margin-right: 42px;
    height: 25px;
    float: right;
    color: gray;
    font-size: 22px;
}

.chart-container {
    margin: 4px 8px 4px 8px;
    border-radius: 1px;
    border: solid thin #c8d7dc;
}

#rowsContainer {
    width: 70%;
}

.graphOuterContainer {
    position: relative;
    float: right;
    width: 30%;
    border: solid thin invisible;
}

.graphInnerContainer {
    position: relative;
    height: 100%;
    width: auto;
    border: solid thin gray;
    border-radius: 5px;
    margin-left: 8px;
}

/*.graph-icon {*/
/*position: relative;*/
/*float: right;*/
/*margin-left: 10px;*/
/*margin-top: 3px;*/
/*margin-bottom: 3px;*/
/*width: 35px;*/
/*height: 35px;*/
/*border: solid thick transparent;*/
/*cursor: pointer;*/
/*}*/

.graph-icon {
    position: relative;
    float: right;
    margin-left: 0px;
    margin-top: 3px;
    margin-bottom: 3px;
    width: 35px;
    height: 35px;
    border: solid thick transparent;
    cursor: pointer;
}

.graph-icon.active {
    border: solid thick white;
    border-radius: 10px;
}

/*#All {*/
/*margin-left: 4px;*/
/*}*/

.please-wait {
    width: 100%;
    position: relative;
    text-align: center;
    font-Family: sans-serif;
    color: darkcyan;
}

.rw-popup-container {
    outline-style: none;
}

.entitiesTable1 {
    width: 25px;
    height: 630px;
    border: solid thin transparent;
}

.entitiesTable2 {
    background-color: white;
    border: solid thin lightgray;
    vertical-align: top;
    width: 300px;
    height: 630px;
}

.outerTable {
    width: 25px;
    height: 630px;
    border: solid thin transparent;
}

.entitieshdng {
    width: auto;
    padding: 7px;
}

.entitiesRow {
    height: 20px;
    vertical-align: top;
    /*background-color: white;*/
}

.entitiesColLeft1 {
    text-align: left;
    width: 70%;
    background-color: white;
    border-bottom: solid thin white;
    height: auto;
}

.entitiesColRight1 {
    text-align: center;
    width: 30%;
    background-color: white;
    border-bottom: solid thin white;
    height: auto;
}

.entitiesColLeft {
    text-align: left;
    width: 70%;
    background-color: #EEEEEE;
    border-bottom: solid thin white;
    height: auto;
}

.entitiesColRight {
    text-align: center;
    width: 30%;
    background-color: #EEEEEE;
    border-bottom: solid thin white;
    height: auto;
}

.responseTableRow {
    background-color: white;
}

.responseColLeft {
    border: solid thin darkgray;
    padding: 5px;
    text-align: left;
}

.responseColRight {
    border: solid thin darkgray;
    padding: 5px;
}

#responseContainer {
    position: fixed;
    width: 670px;
    top: 173px;
    left: 600px;
    height: 660px;
    overflow-y: auto;
}

#responseTitle {
    position: fixed;
    padding-left: 620px;
    padding-top: 9px;

}

#spacer {
    width: 45px;
    min-width: 45px;
    max-width: 45px;
}

#ResponseTable {
    position: static;
    width: 660px;
    min-width: 660px;
    max-width: 660px;
}

.ResponseTable {
    position: static;
    width: 660px;
    min-width: 660px;
    max-width: 660px;
}

#searchTable {
    position: fixed;
    float: left;
    width: 300px;
}

.searchDiv {
    position: relative;
    border: solid thin #CCCCCC;
    top: -7px;
    background-color: white;
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    height: 60px;
    padding: 5px;
}

.searchDiv[placeholder]:empty:before {
    content: attr(placeholder);
    color: #34bdeb;
}

.searchDiv[placeholder]:empty:focus:before {
    content: "";
}

mark {
    /* background: yellow; */
    color: black;
}

.small {
    font: italic 13px sans-serif;
    fill: black;
}

.heavy {
    font: bold 30px sans-serif;
    fill: black;
}

#draggableDiv {
    border: 1px solid gray;
    border-radius: 8px;
    width: 400px;
    opacity: 0.8;
    z-index: 1000;
    position: absolute;
    background-color: white;
}

.wizard {
    width: 400px;
    height: 105px;
    border: solid thin gray;
    border-radius: 5px;
    background-color: white;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 4px;
    position: relative;
    top: -660px;
    left: 10px;
}

.wizardSVG {
    position: relative;
    left: 0px;
    top: -20px;
    height: 85px;
    width: 390px;
}

;
#wizardClose {
    width: auto;
}

#closeDiv {
    position: relative;
    top: -20px;
    float: right;
    vertical-align: top;
}

.arrowLabels {
    pointer-events: none;
    position: relative;
    z-index: 10;
    width: 50px;
    background-color: transparent;
    color: black;
    font-size: 16px;
    font-style: italic;
}

#arrow1 {
    left: 54px;
    top: -81px;
}

#arrow2 {
    left: 175px;
    top: -117px;
}

#arrow3 {
    left: 302px;
    top: -146px;
}

#wizardSelectorDiv {
    pointer-events: none;
    position: relative;
    display: block;
    width: 0px;
    height: 0px;
}

#selectorTable {
    position: relative;
    display: inline;
    top: 2px;
    left: 146px;
    z-index: 20;
    text-align: center;
    background-color: transparent;
}

#methodText {
    color: transparent;
    font-style: italic;
    border: solid thin transparent;
    height: 40px;
    max-width: 70px;
    min-width: 70px;
    background-color: transparent;
}

#methodPicker {
    color: transparent;
    border: solid thin transparent;
    cursor: pointer;
    background-color: transparent;
}

.TMTLogoWhiteBallonsWithTagLine {
    height: 58px;
    padding-left: 8px;
    margin-top: -2px;
    cursor: pointer;
}

#container {
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    top: -10px;
    z-index: 5;
}

.no-navbar {
    background: #f8efdf;
    height: 600px;
}

.topBar {
    background: linear-gradient(rgb(56, 198, 218) 0%, rgb(86, 157, 167) 100%, rgb(115, 115, 115) 100%) white;
}

.blueBubble {
    background: linear-gradient(rgb(56, 198, 218) 0%, rgb(86, 157, 167) 100%, rgb(115, 115, 115) 100%) white;
}

.yellowBubble {
    background: linear-gradient(90deg, rgb(255, 246, 219) 0%, rgb(250, 210, 92) 100%) white;
}

#tagLine {
    font-family: arial;
    margin-top: 10px;
    font-size: 24px;
    color: gray;
    margin-left: 30px;
}

.anim-spacer {
    height: 0px;
}

#cardParagraph {
    text-align: left;
    position: relative;
    padding: 35px;
    padding-top: 30px;
    padding-bottom: 80px;
    margin: 0px;
}

#idiv2 {
    margin-top: 0px;
    margin-bottom: 0px;
}

a.menulink {
    font-size: 15px;
}

#staticUnderlayer {
    --stripe: #f8f4de;
    --bg: white;
    background: linear-gradient(135deg, var(--bg) 25%, transparent 25%) -0px 0,
    linear-gradient(137deg, var(--bg) 25%, transparent 25%) -0px 0,
    linear-gradient(140deg, var(--bg) 25%, transparent 25%) -0px 0,
    linear-gradient(235deg, var(--bg) 5%, transparent 5%) -0px 0,
    linear-gradient(245deg, var(--bg) 5%, transparent 5%) -0px 0,
    linear-gradient(250deg, var(--bg) 5%, transparent 5%) -0px 0,
    linear-gradient(255deg, var(--bg) 5%, transparent 5%) -0px 0;
    background-size: 100px 100px;
    background-color: var(--stripe);
    z-index: 1;
}

#Hdng-0 {
    font-family: Source Sans Pro, sans-serif;;
    font-size: 36px;
    font-weight: 500;
    margin-left: 0px;
    text-align: center;
    color: rgb(54 97 101);
}

#table-0 {
    width: 100%;
    margin: 100px;
}

#Hdng-1 {
    font-family: Source Sans Pro, sans-serif;;
    font-size: 36px;
    font-weight: 500;
    margin-left: 0px;
    text-align: center;
    color: white;
    width: auto;
    margin-top: 50px;

}

#Hdng-2 {
    font-family: Source Sans Pro, sans-serif;;
    font-size: 36px;
    font-weight: 500;
    margin-left: 50px;
    text-align: center;
    color: cadetblue;
    max-width: 450px;
    padding-top: 40px;
}

#tellmethis {
    color: rgb(58, 105, 110);
}

#whySpoken {
    margin: 0 auto;
    width: 700px;
    border: 1px solid transparent;
    background: transparent;
}

#table-1 {
    /*  width: 100%;
     margin: 100px;
     height: auto;*/
    /* margin-top: 30px;
     padding-bottom: 30px; */
}

#animated-div {
    margin-left: -20px;
    margin: 0 auto;
    width: 700px;
    height: 520px;
    border: 1px solid transparent;
    background: transparent;
}

#homePageTranscript {
    margin-top: 15px;
}

.bullets {
    font-family: Source Sans Pro, sans-serif;
    font-size: 29px;
    font-weight: 500;
    color: rgb(54 97 101);
    opacity: 1.0;
    margin: 0 0 14px 0;
}

.whenBullets {
    font-family: Source Sans Pro, sans-serif;
    font-size: 29px;
    font-weight: 500;
    color: white;
    opacity: 0;
    margin: 0 0 14px 0;
}

.slide-fade li {
    transition: all 0.4s ease-out;
    opacity: 0;
}

.slide-fade li.show {
    opacity: 1;
}

#panel-clusters {
    position: relative;
    margin-top: -10px;
    padding: 30px;
    padding-top: 40px;
    height: auto;
    z-index: 5;
    background-color: #c9ddf5;
    border-top: ridge;
    border-bottom: ridge;
    opacity: 1.0;
}

#panel-whenToConsider {
    position: relative;
    background-color: #2d9cab;
    border-top: ridge;
    z-index: 5;
    opacity: 1.0;
}

#panel-cloverLeaf {
    position: relative;
    background-color: white;
    border-top: ridge;
    z-index: 5;
    opacity: 1.0;
}

#panel-creditCard {
    position: relative;
    background-color: #b8dde5;
    border-top: ridge;
    z-index: 5;
    opacity: 1.0;
    padding: 15px;
    padding-bottom: 30px;
}

#interestedInTheViews {
    font-size: 22px;
    font-weight: normal;
    font-family: Source Sans Pro, sans-serif;
    line-height: 35px;
    color: #1b70a1;
    font-weight: 600;

}

#cardHdng {
    font-family: Source Sans Pro, sans-serif;
    padding-top: 40px;
    font-size: 36px;
    font-weight: 400;
    margin-left: 0px;
    text-align: center;
    color: rgb(58, 105, 110);
}

#cardParagraph {
    /*  padding: 130px;
     margin: 0px; */
}

#panel-transcript {
    padding-top: 30px;
    position: relative;
    background-color: #2d9cab;
    border-top: ridge;
    opacity: 1.0;
    z-index: 5;
}

#transcriptText {
    color: white;
    margin-bottom: 300px;
    margin-left: 50px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 36px;
    font-weight: 400;
}

#homePageFooter {
    position: relative;
    margin-top: -15px;
    height: 70px;
    z-index: '5';
    border-top: none;
    opacity: 1.0;
    background-color: #2d9cab;
}

.homePageFooterLinks {
    color: white;
}

.homePageFooterTable {
    width: 100%;
    margin: 20px;
    margin-top: 0px;
    padding-top: 30px;
}

.homePageFooterCols {
    border: gray;
    width: 33%;
    color: white;

}

#panel-transcript {
    height: 670px;
}

#b1 {
    color: #fff8ba
}

#b2 {
    color: #fff8ba /* #91ffa1 */
}

#b3 {
    color: #fff8ba
}

#b4 {
    color: #fff8ba
}

#b5 {
    color: #fff8ba
}

.logo {
    top: 3px;
    height: 47px;
}

.homePageHeading {
    font-family: Source Sans Pro, sans-serif;
    font-size: 36px;
    font-weight: 400;
}

.homePageBulletPts {
    font-family: Source Sans Pro, sans-serif;
    font-size: 24px;
    font-weight: 200;
}

.no-bullets {
    list-style-type: none;
    margin: 20px 10px 10px 40px;
    font-size: 20px;
    font-weight: 200;
}

#whenBulletFeatures {
    margin-top: 25px;
    margin-bottom: 70px;
}

#cloverHeading {
    width: auto;
    margin-top: 50px;
}

#creditCardUL {
    margin-top: 35px;
    margin-bottom: 20px;
}

#creditCardBotRight {
    /*vertical-align: top;*/
    border: solid thin transparent;
}

.card-bullets {
    list-style-type: none;
    font-size: 20px;
    font-weight: 200;
}

#cardBulletsLastItem {
    text-align: left;
}

.homePageAllCaps {
    font-size: 20px;
    font-family: Source Sans Pro, sans-serif;
    color: cadetblue;
}

.homePageGrayCaps {
    font-size: 22px;
    font-weight: normal;
    font-family: Source Sans Pro, sans-serif;
    line-height: 35px;
    color: #1b70a1;
}

.homePageWhiteCaps {
    font-size: 26px;
    font-family: Source Sans Pro, sans-serif;
    color: #fff8ba;
    color: white;
}

.homePageNoCaps {
    font-size: 22px;
    font-family: Source Sans Pro, sans-serif;
    color: darkslategray;
}

#clusters-iframe {
    position: relative;
    top: -14px;
    left: 0px;
    margin-top: 0px;
    /*width: 507px;
    height: 343px;*/
    width: 654px;
    height: 442px;
}

#clusterLeftColText {
    margin-top: 20px;
}

#clusterTopRightTD {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 60px;
}

#clusterBottomRightTD {
    width: 40%;
    vertical-align: top;
}

#clustersHdng {
    font-size: 36px;
    margin-top: 10px;
    margin-bottom: 40px;
    text-align: center;
}

.clusterBulletsLI {
    margin-left: 0px;
    margin-top: 10px;
}

.clusterNoCaps {
    font-size: 24px;
    font-family: Source Sans Pro, sans-serif;
    color: black;
    margin-top: 30px;
}

.clusterGrayCaps {
    font-size: 24px;
    font-family: Source Sans Pro, sans-serif;
    color: black;
}

.clusterBulletsUL {
    margin-left: 0px;
    margin-top: -5px;
}

#clustersSortText {
    margin-top: 30px;
}

#transcriptLeftCell {
    width: 40%;
    padding-right: 20px;
}

#transcriptLRightCell {
    width: 40%;
}

#transcriptIframePara {
    margin-left: 20px;
}

.transcriptBulletsUL {
    margin-left: 0px;
    margin-top: 30px;
}

.transcript-bullets {
    list-style-type: disc;
}

.creditCardSpacer {
    width: 10%;
}

#creditCardTopLeftCell {
    width: 40%;
    text-align: right;
    vertical-align: middle;
    padding-top: 25px;
    border: solid thin transparent;
}

#creditCardBottomLeftCell {
    padding-bottom: 40px;
    vertical-align: bottom;
    text-align: right;
    border: solid thin transparent;
}

.creditCardHdng {
    font-family: Source Sans Pro, sans-serif;
    color: #1B70A1;
    margin-top: 20px;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
}

#collectOpinions {
    position: relative;
    font-size: 22px;
    font-weight: normal;
    font-family: Source Sans Pro, sans-serif;
    line-height: 25px;
    color: #1b70a1;
    font-weight: 600;
}

#footerMidCol {
    text-align: center;
}

#clusterContainerDiv {
    margin-top: -10px;
    margin-bottom: 40px;
    float: right;
    margin-right: 20px;
    background-color: #c9ddf5;
    max-width: 652px;
    min-width: 652px;
    min-height: 425px;
    max-height: 425px;
}

#clusterTableTopLeft, #clusterTableTopRight {
    width: 10%;
}

#clusterRightText {
    max-width: 520px;
    margin-left: 10px;
}

#iFrameCell {
    width: 40%;
    min-width: 652px;
    min-height: 425px;
}

#footerRightCol {
    text-align: right;
    padding-right: 50px;
}

#collectOpinions2 {
    position: relative;
    top: 15px;
    font-size: 22px;
    font-weight: normal;
    font-family: Source Sans Pro, sans-serif;
    line-height: 25px;
    padding-top: 30px;
    color: #1b70a1;
    font-weight: 300;
}

#cloverLefBullets {
    margin-bottom: 100px;
}

#cloverLeafLeft {
    width: 12%;
}

#cloverLeafMidLeft {
    width: 40%;
    position: relative;
    left: 50px;
    top: 30px;
    vertical-align: top;
}

#cloverLeafMidRight {
    width: 40%;
}

#cloverLeafRight {
    width: 8%;
}

#transcriptLeftMarginCell, #transcriptRightMarginCell {
    width: 15%;
}

#transcriptRightCell {
    width: 30%;
}

#creditCardTable {
    position: relative;
    top: -10px;
}

#creditCardTableLeft, #creditCardTableRight {
    width: 15%;
}

#creditCardTableMidLeft {
    width: 35%;
    text-align: right;
}

#creditCardMidRight {
    width: 35%;

}

#embed {
    border-radius: 12px;
}

.waitTime {
    position: relative;
    top: -10px;
}

.waitTimeInput {
    width: 40px;
}

/* ****** Create slider switch ******* */
.switch {
    position: relative;
    display: inline-block;
    width: 105px;
    height: 28px;
    left: 50px;
    top: 8px;
    border: solid thin white;
    border-radius: 15px;
}

.switchText {
    font-family: sans-serif;
    font-size: 14px;
    position: relative;
    top: 5px;
    left: 0px;
    color: white;
}

.switchText:hover {
    color: black;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.sliderL, .sliderR {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eee;
    -webkit-transition: .4s;
    transition: .4s;
}

.sliderL:before, .sliderR:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    bottom: 6px;
    background-color: #eee;
    -webkit-transition: .4s;
    transition: .4s;
}

.sliderL:before {
    left: -40px;
}

.sliderR:before {
    left: 37px;
}

input:checked + .sliderL {
    background-color: #639ea4;
}

input:checked + .sliderR {
    background-color: #beae65;
}

input:focus + .sliderR, input:focus + .sliderL {
    box-shadow: 0 0 1px #639ea4;
}

input:checked + .sliderL:before, input:checked + .sliderR:before {
    -webkit-transform: translateX(46px);
    -ms-transform: translateX(46px);
    transform: translateX(46px);
}

.sliderR.round, .sliderL.round, .sliderD.round {
    border-radius: 34px;
}

.sliderR.round:before, .sliderL.round:before, .sliderD.round:before {
    border-radius: 50%;
}

.sliderD {
    position: absolute;
    cursor: not-allowed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #cdcdcd;
    pointer-events: none;
    cursor: not-allowed;
}

.sliderD:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    bottom: 6px;
    background-color: transparent;
    -webkit-transition: .4s;
    transition: .4s;
}

/* ****** End slider switch ******* */

.scoringHdr {
    width: 5%;
    border: none;
    background-color: transparent;
    text-align: left;
}

.scoring {
    background-color: transparent;
    text-align: center;
    padding: 2px;
    width: 5%;
    border: solid thin gray;
}

.displayTextDiv {
    background-color: #f0f0f0;
}

.logo-uploader {
    position: relative;
    top: 0px;
    display: block;
    background-color: #eef3f4;
    padding-top: 10px;
    width: 400px;
    border-radius: 10px;
    height: 190px;
    text-align: center;
    margin: auto;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='400' height='190'%3e%3crect x='160' y='55' width='80' height='80' stroke='lightgray' stroke-width='1' fill='none'/%3e%3c/svg%3e");
}


.createScoringContainer {
    height: 350px;
}

.createScoringQuestionSpec {
    position: relative;
    top: -25px;
    font-family: arial;
    color: darkblue;
    width: 100%;
    font-weight: 500;
    text-align: center;
    margin-left: 21%;
    width: 77.5%;
    height: auto;
}

.SetScoreRightPanel {
    padding: 10px;
    margin-top: 50px;
    margin-right: 20px;
    min-width: 665px;
    position: relative;
    top: -45px;
    width: 75%;
    float: right;
    min-height: 475px;
    max-height: 475px;
    overflow-y: auto;
    overflow-x: hidden;
    border: groove thin black;
}

.ChatGPTsection {

    border: solid thin gray;
    padding: 5px;
    background-color: #a6e4a440;
    display: block;
    margin-bottom: 15px;
}

.ChatGPTContainer {
    margin-bottom: 5px;
    color: blue;
    width: 100%;
    margin-top: 0px;
}

.GPTpromptLink {
    top: -5px;
    float: left;
    cursor: pointer;
    padding-bottom: 5px;
    padding-left: 7px;
    margin-left: 100px;
}

.GPTresponseLink {
    position: relative;
    top: -5px;
    float: right;
    cursor: pointer;
    padding-right: 23px;
}

.GPTtextArea {
    line-height: 1.5;
    background-color: white;
    font-family: serif;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 35px;
    margin-top: -5px;
    margin-left: 3px;
    vertical-align: top;
    text-align: left;
    width: 95%;
    height: 100px;
    border: solid thin blue;
    resize: none;
}


#maxPtsInstruction {
    float: left;
    margin-bottom: 10px;
    margin-left: 23px;
}

.maxPtsBox {
    position: relative;
    top: -2px;
    border: solid thin black;
    width: 40px;
    height: 20px;
    font-size: 16px;
    resize: none;
    margin: auto;
    float: left;
    background-color: white;
    text-align: center;
}

.correctAnswTable {
    float: left;
    width: 48%;
    min-height: 200px;
    border: solid thin black;
    margin-left: '6px';
}

.wrongAnswTable {
    float: right;
    width: 48%;
    min-height: 200px;
    border: solid thin black;
    margin-left: 10px;
    margin-right: 6px;
}

.wrongAnswTable, .correctAnswTable {
    background-color: #eeeeee;
}

.keywordsTablehdr {
    background-color: #bec7f1;
    width: 90%;
    text-align: left;
    padding-left: 5px;
}

.keywordsAddRow {
    float: left;
    margin-left: 0px;
    font-weight: normal;
    cursor: pointer;
}

.keywordsHdrTitle {
    margin-left: 50px;
    font-weight: bold;
    margin-left: 30px;
}

.keywordsMaxPts {
    float: right;
    margin-right: 10px;
    color: black;
    font-weight: normal;
}

.wtestElementsTableHdr {
    width: 100%;
}

.correctTableContainer, .wcorrectTableContainer {
    min-height: 200px;
    max-height: 200px;
    overflow-y: scroll;
}

.wtestElementsTable {
    width: 100%;
    border-left: solid thin none;
    background-color: transparent;
    border: none;
    padding: 0px;
}

#correctTableBody, #incorrectTableBody {
    width: 100%;
}

.grammarDisplay {
    font-size: 16px;;
    border: solid thin black;
    height: 60px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 3px;
    background-color: lightyellow;
    width: 99%;
}

.pronunciationDisplay {
    font-size: 16px;;
    border: solid thin black;
    height: 60px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 3px;
    background-color: rgb(189, 220, 230);
    width: 99%;
}

.sentimentDisplay {
    font-size: 16px;;
    border: solid thin black;
    height: 60px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 3px;
    background-color: rgb(255, 227, 199);
    width: 99%;
}

.checkBoxContainer {
    display: inline;
    float: left;
    padding-right: 20px;
    font-size: 14px;
    font-weight: normal;
    margin-top: 2px;
}

.methodsTitle {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
}

.methodsList {
    text-align: left;
}

.eggTimerInput {
    width: 40px;
}

.eggTimerOff {
    position: relative;
    top: -10px;
}

.defaultWaitTime {
    position: relative;
    top: -10px;
}

.reportTitleDiv {
    position: fixed;
    top: 150px;
    left: 200px;
    z-Index: 10;
    width: 250px;
    height: 120px;
    background-Color: white;
    border: solid thin black;
    border-Radius: 10px;
    box-Shadow: 2px 2px 10px #888;
    padding: 10px
}

.reportTitleInput {
    margin-Top: 18px;
    border: solid thin gray;
    border-Bottom: 2px solid #ccc;
    padding: 5px;
    width: 85%;
}

.reportTitleLabel {
    display: block;
    margin-top: 2px;
    margin-bottom: 2px;
    width: 250px;
}

#keyWordsSection {
    border: solid thin gray;
    display: block;
    padding: 5px;
    width: 98%;
    background-color: #bec7f166;
    margin-top: 20px;
    padding: 10px;
}

#chatGPTradioButtons {
    margin-bottom: 18px;
}

#chatGPTradioButton2 {
    margin-left: 20px;
}

#noFuncTable {
    border: none;
    position: relative;
    top: -7px;
    width: 100%;
    margin-bottom: 20px;
}

#useFuncTable {
    display: inline-block;
    border: none;
    position: relative;
    top: -27px;
    width: 100%;
    margin-bottom: 20px;
}

#useFuncTable1 {
    display: inline-block;
    border: none;
    position: relative;
    top: -38px;
    width: 100%;
    margin-bottom: -25px;
}

.chatTextAreas {
    width: 100%;
}

#GPTPromptTextArea {
    position: relative;
    float: right;
    line-height: 1.5;
    background-color: white;
    font-family: serif;
    font-size: 16px;
    padding-left: 10px;
    margin-bottom: 10px;
    vertical-align: top;
    text-align: left;
    width: 98%;
    height: 70px;
    border: solid thin #666666;
    resize: none;
}

#GPTAssistantTextArea {
    position: relative;
    float: right;
    line-height: 1.5;
    background-color: white;
    font-family: serif;
    font-size: 16px;
    padding-left: 10px;
    margin-bottom: 10px;
    vertical-align: top;
    text-align: left;
    width: 98%;
    height: 70px;
    border: solid thin #444444;
    resize: none;
}

.GPTAssistantTextLabel {
    padding: 10px;
    text-align: center;
}

.GPTPromptTextLabel {
    padding: 10px;
    text-align: center;
}

#GPTAssistantTextLabel {
    padding: 10px;
    text-align: center;
}

#GPTTopicLabel {
    padding: 10px;
    text-align: center;
}

.GPTTopicDescriptionLabel {
    padding: 10px;
    text-align: center;
}

.GPTTopicText {
    width: 100%;
    border: solid thin #444444;
    resize: none;
    padding: 5px;
}

.GPTTopicDescriptionText {
    width: 97%;
    border: solid thin #444444;
    resize: none;
    padding: 5px;
}

.TopicTableCol1 {
    width: 12.5%;
    border: none;
}

.TopicTableCol2 {
    width: 19%;
    text-align: right;
    border: none;
}

.TopicTableCol3 {
    width: 21%;
    text-align: right;
    border: none;
}

.TopicTableCol4 {
    width: 48%;
    border: none;
}

.ElementTableCol0 {
    width: 2%;
    border: none;
}

.ElementTableCol1 {
    width: 8%;
    border: none;
}

.ElementTableCol2 {
    width: 20%;
    border: none;
}

.ElementTableCol3 {
    width: 18%;
    text-align: right;
    border: none;
}

.ElementTableCol4 {
    width: 42%;
    border: none;
}

#namedElemsHdr {
    color: #444444;
    text-align: left;
    padding-top: 15px;
}

#moreElements {
    position: relative;
    top: 8px;
    font-size: 14px;
    font-family: serif;
    text-decoration: underline;
    cursor: pointer;
    text-align: left;
}

#removeElement {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
}

#clickForEditor {
    margin-top: 10px;
    margin-bottom: 10px;
    color: blue;
    text-decoration: underline;
    /* cursor: pointer; */
}

#chatGPTeditPanel {
    resize: none;
    position: relative;
    width: 48%;
    height: 186px;
    float: left;
    font-family: sans-serif;
    font-size: 12px;
    border: solid thin gray;
    padding: 5px;
}

#chatGPTrenderPanel {
    position: relative;
    width: 48%;
    height: 186px;
    float: right;
    border: solid thin gray;
    background-color: white;
    font-family: sans-serif;
    font-size: 12px;
    padding: 5px;
    overflow-y: auto;
}

#resetTemplate {
    cursor: pointer;
}

#htmlEditor1 {
    cursor: pointer;
}

#htmlEditor2 {
    cursor: pointer;
}

#GPTSystemtextArea {
    position: relative;
    float: right;
    line-height: 1.5;
    background-color: white;
    font-family: serif;
    font-size: 16px;
    padding-left: 10px;
    margin-bottom: 10px;
    vertical-align: top;
    text-align: left;
    width: 98%;
    height: 70px;
    border: solid thin #444444;
    resize: none;
}

.GPTSystemTextLabel {
    padding: 10px;
    text-align: center;
}

.questionOptionsContainer {
    cursor: default;
}


/*styles for create - advanced options modal*/
.questionOptionsContainer:hover {
    cursor: pointer;
}

#previewImage, #previewVideo {
    position: fixed;
    top: 0;
    left: 0;
    border: thick solid white;
    z-index: 10001;
    background: white;
}

#previewHTML {
    border: groove thick lightgray;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    background: white;
    padding: 30px;
}

#modalBodyContent {
    background-color: #a6e4a440;
}

.testElementContainer {
    overflow-y: auto;
    background-color: white;
    border: solid thin gray;
    margin-left: 10px;
    position: relative;
    float: left;
    width: 98%;
    font-size: 14px;
}

.questOptionSelector {
    height: 100px;
    background: white;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
}

.questOptionSelectorDisabled {
    height: 100px;
    background: white;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    pointer-events: none;
}

.questOptionSelectorShort {
    height: 90px;
    background: white;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
}

#questionContainer {
    width: 340px;
}

#questionInnerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#instructionContainer {
    width: 230px;
}

#audioOnlyContainer {
    width: 135px;
}

#audioPlusContainer {
    width: 440px;
}

#circularBorder {
    border: 2px solid #333333;
    border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    width: 50px;
    height: 50px;
    background-color: #eeeeee;
}

#eggTimerInput {
    font-size: 12px;
    width: 35px;
    height: 12px;
    margin-top: 17px;
    margin-left: 0px;
    text-align: right;
    border: solid thin black;
}

.html-editor-container {
    padding: 10px;
    width: 100%;
    min-height: 94%;
    height: 564px;
    border: 1px solid black;
}

.html-editor-subheading {
    width: 100%;
    min-height: 5%;
    font-family: Roboto, sans serif;
    font-size: 14px;
}

.html-editor-preview {
    width: 100%;
    min-height: 50%;
    max-height: 50%;
    overflow-x: auto;
    overflow-y: auto;
}

.html-editor-input {
    width: 100%;
    min-height: 38%;
    overflow-y: auto;
}

.html-editor-button-container {
    width: 100%;
    min-height: 7%;
}

.html-editor-button-bar {
    display: flex;
    justify-content: flex-end;
    margin-top: 7px;
}


.csvSubInfo {
    position: relative;
    float: left;
}

.SubstitutionContainer {
    border: solid thin gray;
    padding: 5px;
    height: 130px;
    border-radius: 5px;
    background: #d3e6f2;
}

.HeadingImportCsv {
    float: left;
    height: 104px;
    width: 65%;
}

.HeadingCurrentCsv {
    float: right;
    height: 104px;
    width: 25;
}

#chooseCsvMessage {
    display: block;
    font-size: 14px;
    position: relative;
    top: -40px;
    left: 135px;
    color: #888888;
    width: 200px;
    pointer-events: none;
}

#upload-csv {
    position: relative;
    left: 340px;
    top: -73px;
}

#modalInfoPopup {
    position: relative;
    display: block;
    border: solid thin gray;
    background-color: white;
    padding: 5px;
    top: 5px;
    left: 0px;
    height: 200px;
    width: 98%;
    overflow-y: auto;
}

#tableContainer {
    width: 100%;
    border: solid thin red;
}

.subListStyle1 {
    color: darkblue;
    font-family: serif;
    font-weight: bold;
}

.subListStyle2 {
    position: relative;
    top: -18px;
    color: black;
    font-family: serif;
    font-weight: normal;
    margin-left: 125px;
}

.subListStyle3 {
    text-align: center;
    padding-bottom: 4px;
}

#SubListInfo {
    color: darkblue;
    font-family: san-serif;
    text-align: center;
    font-weight: normal;
}

#clickToSaveMsg {
    font-style: italic;
    font-size: 14px;
    text-align: center;
    color: red;
}

#subTable {
    border: solid thin lightgray;
    padding: 8px;
    border-collapse: collapse;
    margin: 0 auto;
}

.subListTableCell {
    border: solid thin lightgray;
    padding: 5px;
    text-align: center;
}

#subListClose {
    position: relative;
    float: right;
    margin-right: 5px;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: rgb(101, 176, 186);
    cursor: pointer;
    border: solid thin rgb(101, 176, 186);
}

.loader {
    display: none;
    position: relative;
    float: right;
    border-top: 4px solid rgb(101, 176, 186);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1.2s linear infinite;
    margin-right: 5px;
    margin-top: 2px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#session-expired-message {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    background-color: rgb(100, 100, 100, 0.4);
    align-items: center;
    justify-content: center;
    display: none;
}

#session-expired-message-child {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 20%;
    background-color: rgb(255, 255, 255, 1);
    border: solid gray thin;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 50, 80, 0.7);
}

.retrieved-prompt {
    font-size: 16px;
    /* padding: 2px;
      display: flex;
     border: solid thin transparent;
     margin-bottom: 2px;
     color: black;
     transition: color 0.3s;*/
    background-color: white;
}

.retrieved-prompt:hover {
    background-color: #EEEEEE;
    color: blue
}

.iconBank {
    max-width: 16px;
    min-width: 16px;
    width: 1%;
    border: solid thin darkgray;
    padding: 2px;
}

.iconSave {
    color: green;
    cursor: pointer;
}

.iconSave.inactive {
    color: lightgray;
    cursor: default;
}

.iconRetrieve {
    color: darkred;
    cursor: pointer;
}

.iconRetrieve.inactive {
    color: lightgray;
    cursor: default;
}

.ScoreAllTableCell {
    border: solid thin gray;
    padding: 5px;
    background-color: lightblue;
}

.scoreAllCell {
    border: solid thin gray;
    text-align: center;
    padding: 5px;
}

.scoreAllEmptyCell {
    border: solid thin rgb(245, 243, 238);
    background-color: rgb(245, 243, 238);
    min-height: 20px;
}

.prompt-info-container {
    border: solid thin lightgray;
    background-color: white;
    padding: 10px;
}

.tags-panel-instruction {
    position: relative;
    top: 20px;
    min-height: 50px;
    max-height: 50px;
    font-family: sans-serif;
    font-size: 15px;
    padding-bottom: 8px;
    margin-top: 20px;
}

.tags-panel {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
}

.tags-container {
    min-height: 190px;
    max-height: 190px;
    margin-Left: 14px;
    border: solid thin lightgray;
    overflow-Y: auto;
    background: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 0px;
    width: 100%;
}

.tag-template {
    float: left;
    margin: 5px;
    background-color: #EEEEEE;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #bbb;
    padding: 4px 10px 4px 10px;
}

.tag-close-button {
    font-family: sans-serif;
    font-size: 12px;
    cursor: pointer;
    padding-left: 12px;
    font-weight: normal;
    color: red;
}

.suggestion {
    font-size: 14px;
    padding-left: 6px;
    padding-bottom: 2px;
}

.suggestion:hover {
    font-size: 14px;
    background: #ddd;
    cursor: pointer;
}

.suggestions-input {
    padding: 4px;
    font-size: 14px;
}


.suggestions-container.active {
    display: block;
    position: absolute;
    border: 1px solid #ccc;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 2;
    overflow-y: auto;
}

.suggestions-container {
    display: none;
}

.prompt-search-textInput {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    height: 24px;
    width: 235px;
    font-size: 14px;
}

.prompt-search-textInput {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    height: 24px;
    width: 235px;
    font-size: 14px;
}

#modalBodyContent1 {

    background-color: #a6e4a440;
    padding: 10px;
}

.modalBodyContainer2 {
    border: solid thin gray;
    border-radius: 5px;
    background-color: lightyellow;
    padding: 10px;
    margin-top: 25px;
}

.modalBodyContainer3 {
    border: solid thin gray;
    border-radius: 5px;
    background-color: rgb(230, 213, 182);
    padding: 10px;
    padding-top: 5px;
    margin-top: 25px;
}


.emailReptHdng {
    padding-bottom: 0px;
    text-align: center;
    font-weight: bold;
    width: 100%;
}

.promptHdng {
    padding-bottom: 0px;
    text-align: center;
    font-weight: bold;
}

.subListHdng {
    padding-bottom: 8px;
    text-align: center;
    font-weight: bold;
}

.pushUrlHdng {
    padding-bottom: 0px;
    text-align: center;
    font-weight: bold;
}

.retrieve-search-selector {
    width: 100%;
    height: 75px;
    background: #eee;
    display: flex;
    justify-content: space-around;

}

.retrieve-text-select, .retrieve-tag-select {
    background: white;
    border-radius: 10px;
    padding: 15px;
    font-size: 14px;
    width: 40%;
    margin: 10px 26px;

}

.retrieve-or-container {
    margin: 10px;
    color: rgb(75, 75, 197);
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    cursor: default;
}

.retrieve-or-inner-container {
    margin-top: 15px;
    color: rgb(75, 75, 197);
}

.retrieve-or-inner-container:focus {
    outline: none;
}